/*****************************
***
*** PROGRESSBAR LAYOUTS
***
******************************/

@import "../../assets/scss/00_variables";
@import "../../assets/scss/10_base";

.progressbar {
    &__container {
        @include box-shadow;
        align-items: center;
        background-color: rgba($secondary-color, 0.8);
        border-radius: 0.5rem;
        display: flex;
        font-weight: $font-weight-md;
        justify-content: space-between;
        margin-bottom: $space-md;
        padding: 0.5rem 1rem;
        position: relative;
    }
    &__icon {
        font-size: 2rem;
        margin-right: $space-md;
        color: $secondary-color;
    }
    &__title {
        align-items: center;
        color: $primary-color;
        display: flex;
        font-size: $font-size-sm;
        text-transform: uppercase;
    }
    &__bar {
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: $accent-color;
        height: 0.25rem;
        border-radius: 0.5rem;
        animation: progressAnimation $transition-speed-snail ease-out;
        transition-property: width, background-color;
    }
    &__value {
        color: $accent-color;
    }
}

@keyframes progressAnimation {
    0% {
        width: 0%;
        background-color: lighten($accent-color, 25%);
    }
    100% {
        background-color: $accent-color;
    }
}
