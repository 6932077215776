/*****************************
***
*** EXTENDS
***
******************************/

%extend_headings {
    color: inherit;
    font-weight: $font-weight-lg;
    margin-top: $space-lg;
    margin-bottom: $space-md;
    text-transform: uppercase;
}

/*****************************
***
*** MIXINS
***
******************************/

@mixin desktop {
    @media (min-width: $min-width-desktop) {
        @content;
    }
}

@mixin mobile-only {
    @media (max-width: $min-width-desktop) {
        @content;
    }
}

@mixin grid4cols {
    display: grid;
    gap: $space-md;
    grid-template-columns: 1.5rem repeat(2, 1fr) 1.5rem;
}

@mixin grid6cols {
    display: grid;
    gap: $space-md;
    grid-template-columns: 1.5rem repeat(4, 1fr) 1.5rem;
}

@mixin grid-centered {
    display: grid;
    place-items: center;
}

@mixin hover {
    &:hover {
        background-color: darken($primary-color, 5%);
    }
}

@mixin transition-ease {
    transition: all $transition-speed-medium ease-in-out;
}

@mixin box-shadow {
    // background-color: inherit;
    border-radius: 4px;
    box-shadow: rgba($text-color, 0.2) 0px 3px 3px -2px,
        rgba($text-color, 0.14) 0px 3px 4px 0px,
        rgba($text-color, 0.12) 0px 1px 8px 0px;
}

@mixin box-shadow-hover {
    box-shadow: rgba($text-color, 0.2) 0px 6px 3px -2px,
        rgba($text-color, 0.14) 0px 6px 4px 0px,
        rgba($text-color, 0.12) 0px 2px 8px 0px;
    transform: scale(1.03);
}

/*****************************
***
*** ELEMENTS
***
******************************/

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

/*
*
* Light theme colors
*
*/
body {
    background-color: $primary-color;
    color: $text-color;
    fill: $primary-color;
    font-family: $font-1, $font-2;
    line-height: 1.2;
}

/*
*
* Dark theme colors
*
*/
.dark-theme {
    $bg-dark: #3b3b3b;
    $txt-dark: #f7f7f7;
    $fill-dark: #f7f7f7;

    background-color: $bg-dark;
    color: $txt-dark;
    fill: $fill-dark;

    .footer__content {
        background-color: $bg-dark;
    }
    .contact-list a {
        color: $txt-dark;
    }

    // Dark theme adjustments specific to mobile view
    @include mobile-only {
        .bg-img {
            background-image: linear-gradient(
                    0deg,
                    rgba($bg-dark, 1),
                    rgba($bg-dark, 0.1)
                ),
                url(../images/steffen-1.png);
        }
        .navbar {
            background-color: rgba($bg-dark, 0.9);
        }
        .navbar__nav--item a .label,
        .navbar__nav .dropdown .menu a {
            color: $txt-dark;
        }
        .navbar__nav .active {
            background-color: lighten($bg-dark, 10%);
        }
        .menu-btn div span {
            background: $txt-dark;
            &::after,
            &::before {
                background: $txt-dark;
            }
        }
    }
}

a {
    color: lighten($text-color, 10%);
    font-weight: $font-weight-lg;
    text-decoration: none;
    transition: all $transition-speed-medium;
    &:hover {
        // color: lighten($text-color, 20%);
        color: $secondary-color;
    }
}

button {
    border: none;
    outline: none;
    text-decoration: none;
    &:disabled {
        opacity: 0.5;
    }
}

h1 {
    @extend %extend_headings;
    font-size: 3rem;
}
h2 {
    @extend %extend_headings;
    font-size: 2.5rem;
}
h3 {
    @extend %extend_headings;
    font-size: 2rem;
}
h4 {
    @extend %extend_headings;
    font-size: 1.5rem;
}
h5 {
    @extend %extend_headings;
    font-size: 1rem;
}
h6 {
    @extend %extend_headings;
    font-size: 0.75rem;
}

i {
    display: inline-block;
    vertical-align: middle;
}

p {
    margin-bottom: $space-sm;
    text-align: justify;
    line-height: 1.3;
}

span {
    display: inline-block;
}

ul,
ol {
    line-height: 1.5;
    list-style-type: none;
    margin-bottom: $space-sm;
}

/*****************************
***
*** COMMON CLASSES
***
******************************/

.section__title {
    @extend h1;
    padding-left: $space-md;
    position: relative;

    &::after {
        content: "";
        display: block;
        position: relative;
        width: 20%;
        height: 1rem;
        border: 2px solid;
        border-radius: 2px;
        top: -12%;
        left: -5%;
        border-top-color: transparent;
        border-right-color: transparent;
        border-bottom-color: $accent-color;
        border-left-color: $accent-color;
    }
}
