/*****************************
***
*** CV LAYOUTS
***
******************************/
.cv__title {
    grid-column: 2 / 4;
}

/* 
* Wrapper around main and sidebar 
*/
.cv__container {
    grid-column: 1 / 5;
    svg {
        fill: $secondary-color;
    }
}

/* 
* SIDEBAR
*/
.sidebar-wrapper {
    align-items: center;
    background-color: rgba($secondary-color, 0.05);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    .sidebar-container {
        padding: $space-md $space-sm;
        width: 66%;
        svg {
            height: $icon-size-xs;
            width: $icon-size-xs;
        }
        .contact-list > li {
            display: flex;
            align-items: center;
            a {
                padding-left: $space-sm;
            }
        }
    }
    .title {
        color: $secondary-color;
        text-transform: uppercase;
        font-size: $font-size-md;
        font-weight: $font-weight-lg;
        margin-top: 0;
        margin-bottom: $space-md;
    }
}

/* 
* PROFILE
*/
.profile-container {
    text-align: center;
    width: 100%;
    .name {
        background-color: $secondary-color;
        color: $primary-color;
        font-size: $font-size-xl;
        font-weight: $font-weight-xl;
        padding-top: $space-md;
    }
    .tagline {
        background-color: $secondary-color;
        color: $primary-color;
        font-size: $font-size-md;
        font-style: italic;
        font-weight: $font-weight-sm;
        padding: $space-md 0;
    }
    .bio {
    }
}

/* 
* CONTACT, EDUCATION, LANGUAGES, INTERESTS
*/
.sidebar-container {
    .contact-list {
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        .svg-inline--fa {
            margin-right: $space-md;
            font-size: $font-size-md;
            vertical-align: middle;
            color: $text-color;
        }
        li {
            margin-bottom: $space-lg;
            font-size: $font-size-sm;
        }
        li:last-child {
            margin-bottom: 0;
        }
    }
    .item {
        margin-bottom: 1rem;
        p {
            margin: $space-sm 0;
        }
        .heading,
        .time {
            color: inherit;
            font-weight: $font-weight-lg;
        }
        .meta {
            color: lighten($text-color, 30%);
            font-size: $font-size-sm;
        }
        ul > li {
        }
    }
    .item:last-child {
        margin-bottom: 0;
    }
}

/* 
* MAIN AREA
*/
.main-wrapper {
    display: flex;
    flex-direction: column;
    padding: 0 $space-md;
    .section-title {
        align-items: center;
        color: $secondary-color;
        display: flex;
        font-size: $font-size-lg;
        font-weight: $font-weight-lg;
        margin-bottom: 0;
        margin-top: $space-lg;
        text-transform: uppercase;
        .icon-holder {
            background-color: $secondary-color;
            border-radius: 50%;
            display: inline-block;
            height: 2.5rem;
            margin-right: $space-md;
            padding: 3px;
            padding-top: 6px;
            position: relative;
            text-align: center;
            width: 2.5rem;
            svg {
                fill: $primary-color;
                height: $icon-size-sm;
                width: $icon-size-sm;
            }
        }
    }
    .section-title:first-child {
        margin-bottom: $space-sm;
    }
    /* Summary, Experience, Projects, etc. */
    .section {
        .meta {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .upper-row {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: baseline;
                .title {
                    font-size: $font-size-md;
                    margin-bottom: 0.2rem;
                    width: 60%;
                }
                .time {
                    font-size: $font-size-md;
                    text-align: right;
                    width: 40%;
                }
            }
            .company {
                color: lighten($text-color, 20%);
                font-size: $font-size-sm;
                margin-bottom: 0.5rem;
            }
        }
        .item {
            .title {
                font-weight: $font-weight-lg;
                font-size: $font-size-md;
                margin-top: $space-sm;
            }
            .details {
                margin-bottom: $space-md;
            }
        }
        .intro,
        .skillset {
            margin-top: $space-md;
        }
    }
}

/* 
*
* Desktop styles 
*
*/
@include desktop {
    .cv__title {
        grid-column: 2 / 6;
    }
    .cv__container {
        grid-column: 2 / 6;
        display: grid;
        grid-template-columns: auto 15rem;
        .sidebar-wrapper {
            grid-column: 2 / 3;
            grid-row: 1 / -1;
            .sidebar-container {
                padding-left: $space-md;
                width: 100%;
            }
        }
        .main-wrapper {
            grid-column: 1 / 2;
            grid-row: 1 / -1;
        }
    }
}
