/*****************************
***
*** FUNCTIONS
***
******************************/
@function set-text-color($color) {
    @if (lightness($color) > 40%) {
        @return #333;
    } @else {
        @return #f7f7f7;
    }
}

/*****************************
***
*** COLORS
***
*** Definitions below also drive MUI Themeing in exports.module.scss 
*** and colors.js
***
******************************/

// Light Theme Colors
$primary-color: #fafafa; // body
$secondary-color: #347b98;
$accent-color: #bf360c;
$text-color: #37474f;

// Dark Theme Colors
$dark-primary-color: #37474f; // body

// TODO: define MUI colors for the following
$success-color: #59f175;
$info-color: #f9fcce;
$warning-color: #f1c24b;
$danger-color: #f17a4b;

// Computed variants for MUI
$primary-color-light: lighten($primary-color, 10%);
$primary-color-dark: darken($primary-color, 10%);

$secondary-color-light: lighten($secondary-color, 20%);
$secondary-color-dark: darken($secondary-color, 10%);

$accent-color-light: lighten($accent-color, 20%);
$accent-color-dark: darken($accent-color, 10%);

$text-color-primary: $text-color;
$text-color-secondary: lighten($text-color-primary, 10%);
$text-color-disabled: lighten($text-color-primary, 20%);

$dark-primary-color-light: lighten($dark-primary-color, 20%);
$dark-primary-color-dark: darken($dark-primary-color, 10%);

$dark-secondary-color: lighten($secondary-color, 20%);
$dark-secondary-color-light: lighten($dark-secondary-color, 20%);
$dark-secondary-color-dark: darken($dark-secondary-color, 10%);

$dark-accent-color: lighten($accent-color, 20%);
$dark-accent-color-light: lighten($dark-accent-color, 20%);
$dark-accent-color-dark: darken($dark-accent-color, 10%);

$dark-text-color-primary: $primary-color;
$dark-text-color-secondary: darken($primary-color, 10%);
$dark-text-color-disabled: darken($primary-color, 20%);

$background-default: $dark-primary-color;
$background-paper: $dark-primary-color-light;

/*****************************
***
*** FONTS
***
******************************/
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap");

$font-1: "Roboto";
$font-2: "sans-serif";

$font-size-sm: 0.8rem;
$font-size-md: 1rem;
$font-size-lg: 1.2rem;
$font-size-xl: 2.5rem;

$font-weight-sm: 300;
$font-weight-md: 500;
$font-weight-lg: 700;
$font-weight-xl: 900;

/*****************************
***
*** SPACINGS
***
******************************/
$space-xs: 0.3rem;
$space-sm: 0.5rem;
$space-md: 1rem;
$space-lg: 1.5rem;
$space-xl: 3rem;

/*****************************
***
*** LINE-HEIGHTS
***
******************************/
$line-height-md: 1.1;
$line-height-lg: 1.3;
$line-height-xl: 1.5;

/*****************************
***
*** HEIGHTS
***
******************************/
$height-sm: 1.5rem;
$height-md: 2rem;
$height-lg: 2.5rem;

/*****************************
***
*** MAIN
***
******************************/
$main-width-desktop: 50rem;

/*****************************
***
*** MENU / NAV
***
******************************/
$menu-height-desktop: 5rem;

/*****************************
***
*** ICONS
***
******************************/
$icon-size-xs: 1rem;
$icon-size-sm: 1.5rem;
$icon-size-md: 2rem;
$icon-size-lg: 3rem;
$icon-main-nav: 5rem;

/*****************************
***
*** Z-INDEX
***
******************************/
$z-index-back: -1;
$z-index-normal: 0;
$z-index-tooltip: 10;
$z-index-front: 100;

/*****************************
***
*** MEDIA QUERY BREAK POINT
***
******************************/
$min-width-desktop: 600px;

/*****************************
***
*** TRANSITIONS
***
******************************/
$transition-speed-snail: 3000ms;
$transition-speed-slow: 750ms;
$transition-speed-medium: 500ms;
$transition-speed-fast: 250ms;

$transition-delay-sm: 1s;
$transition-delay-md: 2s;
$transition-delay-lg: 3s;
