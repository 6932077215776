/*****************************
***
*** ICONCAROUSEL LAYOUTS
***
******************************/

@import "../../assets/scss/00_variables";
@import "../../assets/scss/10_base";

.iconcarousel__container {
    $carousel-width: 300px;
    cursor: pointer;
    margin: auto;
    position: relative;
    max-width: $carousel-width;
    /* Play/Pause icon */
    .playstatus {
        color: inherit;
        padding: $space-sm 0px;
        text-align: center;
        .playicon > i {
            font-size: 1rem;
            margin: 0;
            width: 100%;
        }
    }
    .slide {
        /* Main icon */
        display: grid;
        place-content: center;
        svg {
            height: $carousel-width / 4;
            margin: 25px auto 50px auto;
            text-align: center;
            width: $carousel-width / 4;
        }
    }

    /* Caption text */
    .text {
        bottom: 20px;
        color: inherit;
        font-size: $font-size-md;
        font-weight: $font-weight-lg;
        padding: 8px 0px;
        position: absolute;
        text-align: center;
        width: 100%;
    }
    /* The dots/bullets/indicators */
    .dot {
        background-color: lighten($secondary-color, 50%);
        border-radius: 50%;
        cursor: default;
        display: inline-block;
        height: 10px;
        margin: 0 2px;
        width: 10px;
        transition: background-color 0.6s ease;
    }
    .active {
        background-color: $accent-color;
    }
}

/* Fading animation */
.fade {
    animation-name: fade;
    animation-duration: 1.5s;
}

@keyframes fade {
    from {
        opacity: 0.3;
    }
    to {
        opacity: 1;
    }
}
