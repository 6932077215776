/*****************************
***
*** LOADING SPINNER LAYOUTS
***
******************************/

@import "../../assets/scss/00_variables";
@import "../../assets/scss/10_base";

.loadingSpinner {
    background: none;
    height: 100vh;
    overflow: hidden;
    width: 100vw;
}

@keyframes loader {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loader {
    backface-visibility: hidden;
    display: grid;
    height: 100%;
    place-content: center;
    width: 100%;
    transform: translateZ(0) scale(1);
    transform-origin: 0 0; /* see note above */
}

.loader div {
    animation: loader 1s linear infinite;
    border-radius: 50%;
    box-shadow: 0 3.2px 0 0 $secondary-color;
    box-sizing: content-box;
    height: 100px;
    width: 100px;
    transform-origin: 50px 51.6px;
}
