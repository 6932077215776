/*****************************
***
*** ACTION BUTTON LAYOUTS
***
******************************/

@import "../../assets/scss/00_variables";
@import "../../assets/scss/10_base";

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: $font-size-lg;
    margin: 1em 0.8em;
    outline: none;
    text-align: center;
    padding: 1rem;
    position: relative;
    width: 100%;
}
.button.type1 {
    color: $secondary-color;
}
.button.type1.type1::after,
.button.type1.type1::before {
    content: "";
    display: block;
    position: absolute;
    width: 20%;
    height: 20%;
    border: 2px solid;
    transition: all 0.6s ease;
    border-radius: 2px;
}
.button.type1.type1::after {
    bottom: 0;
    right: 0;
    border-top-color: transparent;
    border-left-color: transparent;
    border-bottom-color: $secondary-color;
    border-right-color: $secondary-color;
}
.button.type1.type1::before {
    top: 0;
    left: 0;
    border-bottom-color: transparent;
    border-right-color: transparent;
    border-top-color: $secondary-color;
    border-left-color: $secondary-color;
}
.button.type1.type1:hover:after,
.button.type1.type1:hover:before {
    width: 100%;
    height: 100%;
}
.button.type2 {
    color: $secondary-color;
}
.button.type2.type2:after,
.button.type2.type2:before {
    content: "";
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: $secondary-color;
    transition: all 0.3s ease;
    transform: scale(0.85);
}
.button.type2.type2:hover:before {
    top: 0;
    transform: scale(1);
}
.button.type2.type2:hover:after {
    transform: scale(1);
}
.button.type3 {
    color: $secondary-color;
}
.button.type3.type3::after,
.button.type3.type3::before {
    content: "";
    display: block;
    position: absolute;
    width: 20%;
    height: 20%;
    border: 2px solid;
    transition: all 0.6s ease;
    border-radius: 2px;
}
.button.type3.type3::after {
    bottom: 0;
    right: 0;
    border-top-color: transparent;
    border-left-color: transparent;
    border-bottom-color: $secondary-color;
    border-right-color: $secondary-color;
}
.button.type3.type3::before {
    top: 0;
    left: 0;
    border-bottom-color: transparent;
    border-right-color: transparent;
    border-top-color: $secondary-color;
    border-left-color: $secondary-color;
}
.button.type3.type3:hover:after,
.button.type3.type3:hover:before {
    border-bottom-color: $secondary-color;
    border-right-color: $secondary-color;
    border-top-color: $secondary-color;
    border-left-color: $secondary-color;
    width: 100%;
    height: 100%;
}
.button.type4 {
    color: $secondary-color;
}
.button.type4::after {
    content: "";
    display: block;
    position: absolute;
    height: 2px;
    width: 0;
    left: 0;
    background-color: $secondary-color;
    transition: width 0.3s ease-in-out;
}
.button.type4::after {
    bottom: 0;
}
.button.type4:hover::after {
    width: 50px;
}
