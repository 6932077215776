/*****************************
***
*** MOBILE LAYOUT DEFINITIONS
***
******************************/

.container {
    // background-color: $primary-color;
    display: grid;
    grid-template-columns: 100%; // one column
    grid-template-rows: 3rem auto 1rem;
    grid-template-areas:
        "btn"
        "main"
        "footer";
    height: 100%;
    min-height: 100vh;
    width: 100vw;

    nav {
        grid-row: 1/-1;
    }
    main {
        grid-area: main;
        section {
            /* 4 cols for ALL mobile content sections */
            @include grid4cols;
            padding-bottom: $space-lg; // space btw content and footer toggle
        }
    }
    footer {
        grid-area: footer;
        grid-column: 1/-1;
    }
}

// applied only if pathname="/home"
.bg-img {
    background-image: linear-gradient(
            45deg,
            rgba($primary-color, 1),
            rgba($primary-color, 0.1)
        ),
        url(../images/steffen-1.png);
    background-position: center;
    background-size: cover;
}

/*****************************
***
*** DESKTOP LAYOUT DEFINITIONS
***
******************************/
@include desktop {
    // no full-screen background on desktop
    .bg-img {
        background-image: none;
    }

    .container {
        background-color: transparent;
        grid-template-rows: $menu-height-desktop auto 1rem;
        grid-template-areas:
            "nav"
            "main"
            "footer";
        margin: 0 auto;
        max-width: $main-width-desktop;

        &::after {
            background-image: linear-gradient(
                0deg,
                $primary-color,
                rgba($secondary-color, 0.5)
            );
            clip-path: polygon(33% 0, 100% 0, 66% 100%, 0 100%);
            content: ""; // ::before and ::after both require content
            height: 100%;
            left: 0;
            opacity: 0.3;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: -1;
        }

        // content elements
        .menu-btn {
        }
        nav {
            grid-area: nav;
        }
        main {
            section {
                /* 6 cols for ALL desktop content sections */
                @include grid6cols;
            }
        }
        footer {
        }
    }
}
